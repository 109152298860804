import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { NotificationService } from '@services/notification.service';
import { NotificationDto } from 'src/app/dtos/notification.dto';
import { environment } from 'src/environments/environment';
import { SignalRService } from './signalr.service';
import { SecondsToMilliseconds } from 'src/app/shared/constants/constants';
import * as uuid from 'uuid';
import { Cookies } from 'src/app/shared/constants/cookie.constants';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class NotificationSignalRService {

  private apiUrl = environment.apiUrl + '/notification-hub';

  private hubConnection!: signalR.HubConnection;

  private cookieService = inject(CookieService);
  private notificationService = inject(NotificationService);
  private signalRService = inject(SignalRService);
  private toastr = inject(ToastrService);

  // jwtHelper = inject(JwtHelperService);

  private jwtHelper = new JwtHelperService();
  private checkTokenInterval: NodeJS.Timeout;
  private checkConnectionInterval: NodeJS.Timeout;


  constructor() { }

  public startConnection(): void {
    const token = this.cookieService.get(Cookies.AUTH_TOKEN) ?? '';

    // this.hubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl(`${this.apiUrl}?access_token=${token}`)
    //   .withAutomaticReconnect()
    //   .build();

    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(`${this.apiUrl}`, { accessTokenFactory: () => token }).withAutomaticReconnect().build();

    this.hubConnection.start().then(() => {
      console.log('✅ SignalR Connected');
      this.startTokenCheck();
      this.startConnectionCheck();
      console.log(`Connection ID =`, this.hubConnection.connectionId);
    }).catch(err => {

      console.error('🚨 SignalR Connection Error:', err);

    });

    // this.hubConnection.onreconnecting(() => {
    //   console.log('⚠️ SignalR Reconnecting...');
    // });

    this.hubConnection.onreconnected((connectionId) => {
      console.log(`🔄 SignalR Reconnected with ID: ${connectionId}`);
    });

    this.hubConnection.on('ReceiveNotification', (notification: NotificationDto) => {
      console.log(`New notification received: ID = ${notification.notificationId}, CreatedAt = ${notification.createdAt}`);

      console.log(`New Notification = `,notification);

      const currentNotifications = this.notificationService.notificationSubject.getValue();
      // const updatedNotifications = [notification, ...currentNotifications];

      // Force Shallow Clone (helps with change detection)
      const updatedNotifications = [{ ...notification }, ...currentNotifications];


      this.notificationService.updateNotifications(updatedNotifications);
      // console.log(`New Notification for USER ID = ${notification.notificationId}- Connection Id = ${this.hubConnection.connectionId} - uuid = ${uuid.v4()}`);

      // console.log(notification);

      this.toastr.success('You have a new notification!', '', {
        timeOut: SecondsToMilliseconds.FOUR_SECONDS,
        positionClass: 'toast-top-right',
        progressBar: true,
      });


    });

  }

  public stopConnection(): void {
    if (this.hubConnection) {
      console.log(`Disconnecting SignalR connection Id: ${this.hubConnection.connectionId}...`);
      this.hubConnection.stop();
    }
    // Ensure the token check interval is cleared on logout
    if (this.checkTokenInterval) {
      console.log("Clearing token check interval...");
      clearInterval(this.checkTokenInterval);
    }

    if (this.checkConnectionInterval) {
      console.log("Clearing connection check interval...");
      clearInterval(this.checkConnectionInterval);
    }
  }

  startConnectionCheck(): void {
    // Clear any existing interval to avoid duplicates
    if (this.checkConnectionInterval) {
      clearInterval(this.checkConnectionInterval);
    }

    this.checkConnectionInterval = setInterval(() => {
      const token = this.cookieService.get(Cookies.AUTH_TOKEN) ?? null;
      const isTokenValid = token && !this.jwtHelper.isTokenExpired(token);
      const isDisconnected = !this.hubConnection || this.hubConnection.state === signalR.HubConnectionState.Disconnected;
      // Only reconnect if token is valid and connection is not active
      if (isTokenValid && isDisconnected) {
        console.warn("🔁 Attempting to reconnect to SignalR...");
        // Might call startConnection() instead
        this.hubConnection.start().then(() => {
          console.log("✅ SignalR Reconnected successfully");
        }).catch((err) => {
          console.error("❌ SignalR reconnection failed:", err);
        });
      }

      // If token is invalid and connection exists, disconnect
      if (!isTokenValid && this.hubConnection && this.hubConnection.state !== signalR.HubConnectionState.Disconnected) {
        console.log("🚫 Token invalid or expired — disconnecting SignalR...");
        this.hubConnection.stop();
      }
    }, SecondsToMilliseconds.TEN_SECONDS);
  }

  startTokenCheck(): void {
    // Clear any existing interval before starting a new one
    if (this.checkTokenInterval) {
      clearInterval(this.checkTokenInterval);
    }

    this.checkTokenInterval = setInterval(() => {
      const token = this.cookieService.get(Cookies.AUTH_TOKEN) ?? null;
      if (token == null || this.jwtHelper.isTokenExpired(token)) {
        if (this.hubConnection) {
          console.log(`Disconnecting connection Id:${this.hubConnection.connectionId} in startTokenCheck() SignalR...`);
          this.hubConnection.stop();
        }
        // Stop checking after disconnect
        clearInterval(this.checkTokenInterval);
      }
    }, SecondsToMilliseconds.TEN_SECONDS);
  }

  private sendConnectionIdToServer(): void {
    this.hubConnection.invoke<string>('GetConnectionId').then((connectionId: string) => {
      console.log(`Received Connection ID from server: ${connectionId}`);
      // Send the connection ID to the backend API
      this.signalRService.sendConnectionIdToServer({ connectionId: connectionId }).subscribe({
        next: (data) => {
          console.log('Connection ID stored successfully on the server');
        },
        error: (error) => { },
        complete: () => {

        },
      });

    });
  }

}
