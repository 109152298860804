import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Constants } from '../shared/constants/constants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserRegistrationRequestDto } from '../dtos/user-registeration.dto';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import { Cookies } from '../shared/constants/cookie.constants';
import _ from 'lodash';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserDto } from '../dtos/user.dto';
import { UserService } from './user.service';
import { UserLogoutDto } from '../dtos/user-logout.dto';
import { SwitchRoleRequestDto } from '../dtos/switch-role-request.dto';
import { UserProfileDto } from '../dtos/user-profile.dto';
import { NotificationSignalRService } from './signalr/notification-signal-r.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = environment.apiUrl + '/api/account';

  private jwtHelper = new JwtHelperService(); // Create an instance of JwtHelperService

  notificationSignalRService = inject(NotificationSignalRService);

  constructor(private http: HttpClient, private router: Router, private userService: UserService, private cookieService: CookieService) { }

  /**
   * If the token is expired, returns false else true
   */
  isAuthenticated(): boolean {
    //console.log("Calling isAuthenticated()");

    if (!this.cookieService.check(Cookies.AUTH_TOKEN)) {
      return false;
    }

    const token = this.cookieService.get(Cookies.AUTH_TOKEN);

    if (token == null || token == undefined || _.isEmpty(token)) {
      return false;
    }
    // Check whether the token is expired
    return !this.jwtHelper.isTokenExpired(this.getToken());
  }

  login(email: string, password: string): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.apiUrl}/${'login'}`, { email: email, password: password })
      .pipe(
        tap(res => {
          // this.setUserInfo({
          //   userRole: res.currentRoleId,
          //   userRoleId: res.currentRoleId //res.userRoleId
          // });


        })
      );
  }

  getUserProfile(): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(`${this.apiUrl}/${'user-profile'}`);
  }

  switchRoles(switchRole: SwitchRoleRequestDto): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/${'switch-role'}`, switchRole);
  }

  setUserAfterLogin(user: UserDto): void {
    this.userService.setUser(user);
  }

  register(user: UserRegistrationRequestDto): Observable<UserRegistrationRequestDto> {
    return this.http.post<UserRegistrationRequestDto>(`${this.apiUrl}/${'registration'}`, user);
  }

  confirmRegistration(token: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/${'confirm-registration'}?${"token="}${token}`, {}, { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }) });
  }

  logout(): void {
    let user = this.userService.getUser();

    if (user) {
      let userLogout = new UserLogoutDto(user?.currentRoleId, user?.sessionUid);
      this.http.post<UserLogoutDto>(`${this.apiUrl}/logout`, userLogout).subscribe({
        next: (data) => {

          this.notificationSignalRService.stopConnection();
          
          if (this.deleteAuthToken()) {
            this.userService.removeUser();
            console.log("Redirecting...... to login");
            this.router.navigate(['login']);
          }
          this.cookieService.delete(Cookies.USER_THEME,'/',environment.cookieDomain);
        },
        error: (error) => {

          this.forceLogout();

        },
        complete: () => {

        },
      });
    } else {
      // Force log out
      this.forceLogout();

    }

  }

  forceLogout() {
    if (this.deleteAuthToken()) {
      this.userService.removeUser();
      console.log("Force Deleted Auth token");
      this.router.navigate(['login']);
    }
  }

  setToken(token: string) {
    this.cookieService.set(Constants.STORAGE_TOKEN, token, this.setCookiesOptions());
  }

  setCookiesOptions(isLogout: boolean = false) {
    let expiredDate: any;
    if (isLogout) {
      expiredDate = new Date(0).toUTCString()
    } else {
      expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() + 20);
    }

    let options = {};
    options = {
      expires: expiredDate,
      domain: environment.cookieDomain,
      path: '/'
    }
    return options;
  }

  // ----------------------------------------------------- Token methods
  getToken(): string | null {
    return this.cookieService.get(Cookies.AUTH_TOKEN);
  }
  /**
   * 
   * @returns True if the auth token was deleted
   */
  deleteAuthToken(): boolean {
    console.log('deleteAuthToken()')
    this.cookieService.delete(Cookies.AUTH_TOKEN, '/', environment.cookieDomain);
    if (!this.cookieService.check(Cookies.AUTH_TOKEN)) {
      return true;
    }
    return false;
  }

  // Check if the token is expired
  isTokenExpired(): boolean {
    const token = this.getToken();
    return token ? this.jwtHelper.isTokenExpired(token) : true;
  }

  decodeToken(): any {
    const token = this.getToken();
    return token ? this.jwtHelper.decodeToken(token) : null;
  }

  getTokenExpirationDate(): Date | null {
    const token = this.getToken();
    return token ? this.jwtHelper.getTokenExpirationDate(token) : null;
  }

  // --------------------------------- Claims

  getClaimValue(key: string): string | null {

    const token = this.getToken();

    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);

      if (decodedToken && decodedToken[key]) {
        return decodedToken[key];
      }
    }

    return null;
  }

  // -------------------------------------- 



  // #region User Info
  // get userInfo(): any {
  //   if (!this.cookieService.get(Constants.USER_INFO)) {
  //     return null;
  //   }
  //   return JSON.parse(this.cookieService.get(Constants.USER_INFO)!);
  // }

  // setUserInfo(user: any) {
  //   console.log("setUserInfo(user: any)")
  //   console.log(user)
  //   console.log(JSON.stringify(user))
  //   this.cookieService.set(Constants.USER_INFO, JSON.stringify(user), this.setCookiesOptions());
  // }
  //#endregion

}
